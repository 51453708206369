<!-- TODO: Change the background color to pure white once we get updated GIF files -->
<div
  class="tw-flex tw-flex-col tw-items-center tw-bg-[#FDFCFE]"
  *transloco="let t"
>
  <div class="tw-p-4 xl:tw-p-6" [attr.data-testid]="TEST_IDS.slideContent">
    <ng-content />
  </div>

  <div
    class="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-6 tw-bg-blue-very-dark/[0.08] tw-p-8 tw-text-blue-very-dark xl:tw-gap-8 xl:tw-p-12"
  >
    <ng-content select="app-demo-slide-explanation" />

    <div
      class="tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-8"
      [attr.data-testid]="TEST_IDS.slidesNavigation"
    >
      @if (slidesCount() > 1) {
        <div class="tw-min-w-[100px] tw-flex-1 tw-text-end">
          @if (hasPreviousSlide()) {
            <app-button
              [attr.data-testid]="TEST_IDS.previousSlideButton"
              [backgroundColor]="'transparent'"
              (click)="onGoToPreviousSlide()"
            >
              {{ t('guidesModule.demoSlidesModal.previousSlideLabel') }}
            </app-button>
          }
        </div>

        <div
          class="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-4"
          [attr.data-testid]="TEST_IDS.slidesNavigationDots"
        >
          @for (oneSlideDot of paginationDots(); track oneSlideDot.index) {
            <div
              class="tw-flex tw-size-[12px] tw-items-center tw-justify-center"
            >
              <div
                class="tw-rounded-full tw-transition-all"
                [ngClass]="{
                  'tw-size-[12px] tw-bg-blue-very-dark': oneSlideDot.isCurrent,
                  'tw-size-[8px] tw-bg-blue-very-dark/[0.35]':
                    !oneSlideDot.isCurrent,
                }"
              ></div>
            </div>
          }
        </div>

        <div class="tw-min-w-[100px] tw-flex-1">
          @if (!isLastSlide()) {
            <app-button
              [attr.data-testid]="TEST_IDS.nextSlideButton"
              [backgroundColor]="'transparent'"
              (click)="onGoToNextSlide()"
            >
              {{ t('guidesModule.demoSlidesModal.nextSlideLabel') }}
            </app-button>
          } @else {
            <ng-container *ngTemplateOutlet="okGotItButtonTemplate" />
          }
        </div>
      } @else {
        <ng-container *ngTemplateOutlet="okGotItButtonTemplate" />
      }
    </div>
  </div>

  <ng-template #okGotItButtonTemplate>
    <app-button
      [attr.data-testid]="TEST_IDS.okGotItButton"
      [backgroundColor]="'info'"
      (click)="onOkGotIt()"
    >
      {{ t('guidesModule.demoSlidesModal.okGotIt') }}
    </app-button>
  </ng-template>
</div>
