import { Directive, inject, TemplateRef } from '@angular/core';

/**
 * NOTE:
 *
 * Use this directive within {@link DemoSlideComponent} to mark a template that
 * should be used to display an image / animation / graphic content.
 */
@Directive({
  selector: '[appDemoSlideContentTemplate]',
})
export class DemoSlideContentTemplateDirective {
  readonly template: TemplateRef<unknown> = inject(TemplateRef);
}
