import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { DemoSlideExplanationContentComponent } from '../demo-slides-modal/demo-slide-explanation-content/demo-slide-explanation-content.component';
import { DemoSlideExplanationTitleComponent } from '../demo-slides-modal/demo-slide-explanation-title/demo-slide-explanation-title.component';
import { DemoSlideComponent } from '../demo-slides-modal/demo-slide/demo-slide.component';
import { DemoSlidesModalComponent } from '../demo-slides-modal/demo-slides-modal.component';
import { DemoSlideContentTemplateDirective } from '../demo-slides-modal/directives/demo-slide-content-template.directive';
import { DemoSlideExplanationTemplateDirective } from '../demo-slides-modal/directives/demo-slide-explanation-template.directive';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DemoSlideComponent,
    DemoSlideContentTemplateDirective,
    DemoSlideExplanationContentComponent,
    DemoSlideExplanationTitleComponent,
    DemoSlideExplanationTemplateDirective,
    DemoSlidesModalComponent,
    NgOptimizedImage,
    TranslocoDirective,
  ],
  selector: 'app-supplier-segments-demo',
  templateUrl: './supplier-segments-demo.component.html',
})
export class SupplierSegmentsDemoComponent {
  readonly slideContentCssClasses = 'tw-flex tw-h-[300px] tw-items-center';
}
