import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingComponent } from '../../../../shared/components/heading/heading.component';
import { DEMO_SLIDES_MODAL_TEST_IDS } from '../demo-slides-modal.models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [HeadingComponent],
  selector: 'app-demo-slide-explanation-title',
  templateUrl: './demo-slide-explanation-title.component.html',
})
export class DemoSlideExplanationTitleComponent {
  readonly TEST_IDS = DEMO_SLIDES_MODAL_TEST_IDS;
}
