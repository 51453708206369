import { DialogRef } from '@angular/cdk/dialog';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChildren,
  inject,
  signal,
  type AfterContentInit,
} from '@angular/core';
import { assert } from '../../../shared/helpers/assert/assert.helper';
import { DemoSlideExplanationComponent } from './demo-slide-explanation/demo-slide-explanation.component';
import { DemoSlideComponent } from './demo-slide/demo-slide.component';
import { DemoSlideModalContentComponent } from './demo-slides-modal-content/demo-slide-modal-content.component';
import {
  DEMO_SLIDES_MODAL_TEST_IDS,
  type DemoSlidesModalOutput,
} from './demo-slides-modal.models';

type DemoSlidesModalReference = DialogRef<DemoSlidesModalOutput>;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DemoSlideExplanationComponent,
    DemoSlideModalContentComponent,
    NgTemplateOutlet,
  ],
  selector: 'app-demo-slides-modal',
  templateUrl: './demo-slides-modal.component.html',
})
export class DemoSlidesModalComponent implements AfterContentInit {
  readonly #dialogReference = inject<DemoSlidesModalReference>(DialogRef);

  readonly slides = contentChildren(DemoSlideComponent);

  readonly currentSlide = signal<DemoSlideComponent | undefined>(undefined);

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly slidesCount = computed<number>(() => this.slides().length);

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly currentSlideIndex = computed<number>(() => {
    const currentSlide = this.currentSlide();
    const slides = this.slides();

    if (currentSlide === undefined) {
      return -1;
    }

    return slides.indexOf(currentSlide);
  });

  readonly TEST_IDS = DEMO_SLIDES_MODAL_TEST_IDS;

  ngAfterContentInit(): void {
    assert(this.slides().length > 0, 'There are no slides');

    this.#loadSlide(0);
  }

  onGoToPreviousSlide(): void {
    const previousSlideIndex = this.currentSlideIndex() - 1;
    if (previousSlideIndex < 0) {
      return;
    }

    this.#loadSlide(previousSlideIndex);
  }

  onGoToNextSlide(): void {
    const nextSlideIndex = this.currentSlideIndex() + 1;
    if (nextSlideIndex >= this.slidesCount()) {
      return;
    }

    this.#loadSlide(nextSlideIndex);
  }

  onOkGotIt(): void {
    this.#close();
  }

  #close(): void {
    this.#dialogReference.close({
      isGuideCompleted: true,
    });
  }

  #loadSlide(index: number): void {
    assert(index >= 0 && index < this.slides().length, 'Invalid slide index');

    this.currentSlide.set(this.slides()[index]);
  }
}
