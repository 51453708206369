import { inject, Injectable, type Type } from '@angular/core';
import {
  DialogService,
  type DialogEvents,
} from '../../../core/services/dialog.service';
import type {
  DemoSlidesModalInput,
  DemoSlidesModalOutput,
} from './demo-slides-modal.models';

const defaultDialogWidth = '800px';

@Injectable({ providedIn: 'root' })
export class DemoSlidesModalService {
  readonly #dialogService = inject(DialogService);

  open<Component>(
    component: Type<Component>,
    options?: DemoSlidesModalInput,
  ): DialogEvents<DemoSlidesModalOutput> {
    return this.#dialogService.open<
      Component,
      DemoSlidesModalInput,
      DemoSlidesModalOutput
    >(component, {
      width: options?.dialogWidth ?? defaultDialogWidth,
      data: options,
    });
  }
}
