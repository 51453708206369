import {
  ChangeDetectionStrategy,
  Component,
  contentChild,
} from '@angular/core';
import { DemoSlideContentTemplateDirective } from '../directives/demo-slide-content-template.directive';
import { DemoSlideExplanationTemplateDirective } from '../directives/demo-slide-explanation-template.directive';

/**
 * NOTE:
 *
 * Use this component within {@link DemoSlidesModalComponent} to declare some demo slides.
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-demo-slide',
  template: '',
})
export class DemoSlideComponent {
  readonly contentTemplate = contentChild.required(
    DemoSlideContentTemplateDirective,
  );
  readonly explanationsTemplate = contentChild.required(
    DemoSlideExplanationTemplateDirective,
  );
}
