<div
  class="tw-overflow-hidden tw-rounded-lg tw-shadow-3 print:tw-hidden"
  [attr.data-testid]="TEST_IDS.modalId"
>
  @if (currentSlide(); as currentSlide) {
    <app-demo-slides-modal-content
      [slideIndex]="currentSlideIndex()"
      [slidesCount]="slidesCount()"
      (goToNextSlide)="onGoToNextSlide()"
      (goToPreviousSlide)="onGoToPreviousSlide()"
      (okGotIt)="onOkGotIt()"
    >
      <app-demo-slide-explanation>
        <ng-container
          *ngTemplateOutlet="currentSlide.explanationsTemplate().template"
        />
      </app-demo-slide-explanation>

      <ng-container
        *ngTemplateOutlet="currentSlide.contentTemplate().template"
      />
    </app-demo-slides-modal-content>
  }
</div>
