import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { DEMO_SLIDES_MODAL_TEST_IDS } from '../demo-slides-modal.models';

interface PaginationDot {
  index: number;
  isCurrent: boolean;
}

/**
 * @internal
 *
 * Please don't use this component directly. It is for internal use only.
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, NgClass, NgTemplateOutlet, TranslocoDirective],
  selector: 'app-demo-slides-modal-content',
  templateUrl: './demo-slide-modal-content.component.html',
})
export class DemoSlideModalContentComponent {
  readonly slidesCount = input.required<number>();
  readonly slideIndex = input.required<number>();

  readonly goToPreviousSlide = output();
  readonly goToNextSlide = output();
  readonly okGotIt = output();

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly hasPreviousSlide = computed<boolean>(() => this.slideIndex() > 0);

  readonly isLastSlide = computed<boolean>(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => this.slideIndex() === this.slidesCount() - 1,
  );

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly paginationDots = computed<PaginationDot[]>(() => {
    return Array.from({ length: this.slidesCount() }, (_, index) => ({
      index,
      isCurrent: index === this.slideIndex(),
    }));
  });

  readonly TEST_IDS = DEMO_SLIDES_MODAL_TEST_IDS;

  onGoToPreviousSlide(): void {
    this.goToPreviousSlide.emit();
  }

  onGoToNextSlide(): void {
    this.goToNextSlide.emit();
  }

  onOkGotIt(): void {
    this.okGotIt.emit();
  }
}
