import { Directive, inject, TemplateRef } from '@angular/core';

/**
 * NOTE:
 *
 * Use this directive within {@link DemoSlideComponent} to mark a template that
 * should be used to render a slide explanation / description.
 */
@Directive({
  selector: '[appDemoSlideExplanationTemplate]',
})
export class DemoSlideExplanationTemplateDirective {
  readonly template: TemplateRef<unknown> = inject(TemplateRef);
}
