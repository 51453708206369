import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DEMO_SLIDES_MODAL_TEST_IDS } from '../demo-slides-modal.models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
  selector: 'app-demo-slide-explanation-content',
  templateUrl: './demo-slide-explanation-content.component.html',
})
export class DemoSlideExplanationContentComponent {
  readonly TEST_IDS = DEMO_SLIDES_MODAL_TEST_IDS;
}
