export const DEMO_SLIDES_MODAL_TEST_IDS = {
  explanationContent: 'demoSlideExplanationContent',
  explanationTitle: 'demoSlideExplanationTitle',
  modalId: 'demoSlidesModal',
  nextSlideButton: 'nextSlideButton',
  okGotItButton: 'okGotIdButton',
  previousSlideButton: 'previousSlideButton',
  slideContent: 'slideContent',
  slideExplanation: 'slideExplanationText',
  slidesNavigation: 'slidesNavigation',
  slidesNavigationDots: 'slidesNavigationDots',
};

export interface DemoSlidesModalInput {
  dialogWidth?: string;
}

export interface DemoSlidesModalOutput {
  isGuideCompleted: boolean;
}
