<!-- QUESTION: Since our demo is a set of GIF files... shall we prepare separate GIFs
for each supported language and load them from translations? Or perhaps we should
skip using GIFs and use real components instead (which we could animate triggering
different JavaScript events)? This is something to consider. -->

<app-demo-slides-modal *transloco="let t">
  <!-- NOTE: Slide 1 -->
  <app-demo-slide>
    <ng-template appDemoSlideContentTemplate>
      <div [class]="slideContentCssClasses">
        <img
          class="tw-h-[222px] tw-w-[400px]"
          [alt]="
            t('guidesModule.supplierSegmentsDemoSlides.slide1.description')
          "
          [height]="444"
          [ngSrc]="
            '/assets/img/demos/supplier-segments/supplier-segmentation-1.gif'
          "
          [priority]="true"
          [width]="800"
        />
      </div>
    </ng-template>

    <ng-template appDemoSlideExplanationTemplate>
      <app-demo-slide-explanation-title>
        {{ t('guidesModule.supplierSegmentsDemoSlides.slide1.title') }}
      </app-demo-slide-explanation-title>

      <app-demo-slide-explanation-content>
        {{ t('guidesModule.supplierSegmentsDemoSlides.slide1.description') }}
      </app-demo-slide-explanation-content>
    </ng-template>
  </app-demo-slide>

  <!-- NOTE: Slide 2 -->
  <app-demo-slide>
    <ng-template appDemoSlideContentTemplate>
      <div [class]="slideContentCssClasses">
        <img
          class="tw-h-[222px] tw-w-[400px]"
          [alt]="
            t('guidesModule.supplierSegmentsDemoSlides.slide2.description')
          "
          [height]="444"
          [ngSrc]="
            '/assets/img/demos/supplier-segments/supplier-segmentation-2.gif'
          "
          [priority]="true"
          [width]="800"
        />
      </div>
    </ng-template>

    <ng-template appDemoSlideExplanationTemplate>
      <app-demo-slide-explanation-title>
        {{ t('guidesModule.supplierSegmentsDemoSlides.slide2.title') }}
      </app-demo-slide-explanation-title>

      <app-demo-slide-explanation-content>
        {{ t('guidesModule.supplierSegmentsDemoSlides.slide2.description') }}
      </app-demo-slide-explanation-content>
    </ng-template>
  </app-demo-slide>

  <!-- NOTE: Slide 3 -->
  <app-demo-slide>
    <ng-template appDemoSlideContentTemplate>
      <div [class]="slideContentCssClasses">
        <img
          class="tw-h-[300px] tw-w-[200px]"
          [alt]="
            t('guidesModule.supplierSegmentsDemoSlides.slide3.description')
          "
          [height]="600"
          [ngSrc]="
            '/assets/img/demos/supplier-segments/supplier-segmentation-3.gif'
          "
          [priority]="true"
          [width]="400"
        />
      </div>
    </ng-template>

    <ng-template appDemoSlideExplanationTemplate>
      <app-demo-slide-explanation-title>
        {{ t('guidesModule.supplierSegmentsDemoSlides.slide3.title') }}
      </app-demo-slide-explanation-title>

      <app-demo-slide-explanation-content>
        {{ t('guidesModule.supplierSegmentsDemoSlides.slide3.description') }}
      </app-demo-slide-explanation-content>
    </ng-template>
  </app-demo-slide>
</app-demo-slides-modal>
